/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * ZEP QUIZ
 * Zep Quiz API
 * OpenAPI spec version: 0.0.1
 */
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';

import type { BodyType, ErrorType } from '../../httpClient';
import { httpsInstance } from '../../httpClient';
import type {
  AuthEmailConfirmReq,
  AuthEmailReq,
  AuthorizationForGoogleTokenRequest,
  AuthReq,
  GoogleAuthorizationCallbackParams,
  RedirectView,
  SuccessBodyAuthEmailRes,
  SuccessBodyAuthResWithPlanType,
  SuccessBodyCountryCodeRes,
  SuccessBodyGetUserAuthInfoResponse,
  SuccessBodyMapStringString,
  SuccessBodyString,
} from '.././_model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const loginWithGoogle = (
  authReq: BodyType<AuthReq>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SuccessBodyAuthResWithPlanType>(
    {
      url: `/login/google`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: authReq,
    },
    options,
  );
};

export const getLoginWithGoogleMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof loginWithGoogle>>,
    TError,
    { data: BodyType<AuthReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof loginWithGoogle>>,
  TError,
  { data: BodyType<AuthReq> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof loginWithGoogle>>,
    { data: BodyType<AuthReq> }
  > = props => {
    const { data } = props ?? {};

    return loginWithGoogle(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type LoginWithGoogleMutationResult = NonNullable<
  Awaited<ReturnType<typeof loginWithGoogle>>
>;
export type LoginWithGoogleMutationBody = BodyType<AuthReq>;
export type LoginWithGoogleMutationError = ErrorType<unknown>;

export const useLoginWithGoogle = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof loginWithGoogle>>,
    TError,
    { data: BodyType<AuthReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof loginWithGoogle>>,
  TError,
  { data: BodyType<AuthReq> },
  TContext
> => {
  const mutationOptions = getLoginWithGoogleMutationOptions(options);

  return useMutation(mutationOptions);
};
export const requestEmailLoginCode = (
  authEmailReq: BodyType<AuthEmailReq>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SuccessBodyAuthEmailRes>(
    {
      url: `/login/email`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: authEmailReq,
    },
    options,
  );
};

export const getRequestEmailLoginCodeMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof requestEmailLoginCode>>,
    TError,
    { data: BodyType<AuthEmailReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof requestEmailLoginCode>>,
  TError,
  { data: BodyType<AuthEmailReq> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof requestEmailLoginCode>>,
    { data: BodyType<AuthEmailReq> }
  > = props => {
    const { data } = props ?? {};

    return requestEmailLoginCode(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RequestEmailLoginCodeMutationResult = NonNullable<
  Awaited<ReturnType<typeof requestEmailLoginCode>>
>;
export type RequestEmailLoginCodeMutationBody = BodyType<AuthEmailReq>;
export type RequestEmailLoginCodeMutationError = ErrorType<unknown>;

export const useRequestEmailLoginCode = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof requestEmailLoginCode>>,
    TError,
    { data: BodyType<AuthEmailReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof requestEmailLoginCode>>,
  TError,
  { data: BodyType<AuthEmailReq> },
  TContext
> => {
  const mutationOptions = getRequestEmailLoginCodeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const confirmEmailLogin = (
  authEmailConfirmReq: BodyType<AuthEmailConfirmReq>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SuccessBodyAuthResWithPlanType>(
    {
      url: `/login/email/confirm`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: authEmailConfirmReq,
    },
    options,
  );
};

export const getConfirmEmailLoginMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof confirmEmailLogin>>,
    TError,
    { data: BodyType<AuthEmailConfirmReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof confirmEmailLogin>>,
  TError,
  { data: BodyType<AuthEmailConfirmReq> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof confirmEmailLogin>>,
    { data: BodyType<AuthEmailConfirmReq> }
  > = props => {
    const { data } = props ?? {};

    return confirmEmailLogin(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ConfirmEmailLoginMutationResult = NonNullable<
  Awaited<ReturnType<typeof confirmEmailLogin>>
>;
export type ConfirmEmailLoginMutationBody = BodyType<AuthEmailConfirmReq>;
export type ConfirmEmailLoginMutationError = ErrorType<unknown>;

export const useConfirmEmailLogin = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof confirmEmailLogin>>,
    TError,
    { data: BodyType<AuthEmailConfirmReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof confirmEmailLogin>>,
  TError,
  { data: BodyType<AuthEmailConfirmReq> },
  TContext
> => {
  const mutationOptions = getConfirmEmailLoginMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getTokenAndGoogleAccessTokenByAuthorizationCode = (
  authorizationForGoogleTokenRequest: BodyType<AuthorizationForGoogleTokenRequest>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SuccessBodyMapStringString>(
    {
      url: `/auth/google/token`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: authorizationForGoogleTokenRequest,
    },
    options,
  );
};

export const getGetTokenAndGoogleAccessTokenByAuthorizationCodeMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof getTokenAndGoogleAccessTokenByAuthorizationCode>
      >,
      TError,
      { data: BodyType<AuthorizationForGoogleTokenRequest> },
      TContext
    >;
    request?: SecondParameter<typeof httpsInstance>;
  }): UseMutationOptions<
    Awaited<ReturnType<typeof getTokenAndGoogleAccessTokenByAuthorizationCode>>,
    TError,
    { data: BodyType<AuthorizationForGoogleTokenRequest> },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof getTokenAndGoogleAccessTokenByAuthorizationCode>
      >,
      { data: BodyType<AuthorizationForGoogleTokenRequest> }
    > = props => {
      const { data } = props ?? {};

      return getTokenAndGoogleAccessTokenByAuthorizationCode(
        data,
        requestOptions,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type GetTokenAndGoogleAccessTokenByAuthorizationCodeMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof getTokenAndGoogleAccessTokenByAuthorizationCode>>
  >;
export type GetTokenAndGoogleAccessTokenByAuthorizationCodeMutationBody =
  BodyType<AuthorizationForGoogleTokenRequest>;
export type GetTokenAndGoogleAccessTokenByAuthorizationCodeMutationError =
  ErrorType<unknown>;

export const useGetTokenAndGoogleAccessTokenByAuthorizationCode = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getTokenAndGoogleAccessTokenByAuthorizationCode>>,
    TError,
    { data: BodyType<AuthorizationForGoogleTokenRequest> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getTokenAndGoogleAccessTokenByAuthorizationCode>>,
  TError,
  { data: BodyType<AuthorizationForGoogleTokenRequest> },
  TContext
> => {
  const mutationOptions =
    getGetTokenAndGoogleAccessTokenByAuthorizationCodeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const refreshGoogleAccessToken = (
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SuccessBodyString>(
    { url: `/auth/google/refresh-token`, method: 'POST' },
    options,
  );
};

export const getRefreshGoogleAccessTokenMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof refreshGoogleAccessToken>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof refreshGoogleAccessToken>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof refreshGoogleAccessToken>>,
    void
  > = () => {
    return refreshGoogleAccessToken(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RefreshGoogleAccessTokenMutationResult = NonNullable<
  Awaited<ReturnType<typeof refreshGoogleAccessToken>>
>;

export type RefreshGoogleAccessTokenMutationError = ErrorType<unknown>;

export const useRefreshGoogleAccessToken = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof refreshGoogleAccessToken>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof refreshGoogleAccessToken>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getRefreshGoogleAccessTokenMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getIso = (
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<SuccessBodyCountryCodeRes>(
    { url: `/utils/iso`, method: 'GET', signal },
    options,
  );
};

export const getGetIsoQueryKey = () => {
  return [`/utils/iso`] as const;
};

export const getGetIsoQueryOptions = <
  TData = Awaited<ReturnType<typeof getIso>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getIso>>, TError, TData>
  >;
  request?: SecondParameter<typeof httpsInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetIsoQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getIso>>> = ({
    signal,
  }) => getIso(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getIso>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetIsoQueryResult = NonNullable<Awaited<ReturnType<typeof getIso>>>;
export type GetIsoQueryError = ErrorType<unknown>;

export function useGetIso<
  TData = Awaited<ReturnType<typeof getIso>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getIso>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getIso>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof httpsInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetIso<
  TData = Awaited<ReturnType<typeof getIso>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getIso>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getIso>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetIso<
  TData = Awaited<ReturnType<typeof getIso>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getIso>>, TError, TData>
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetIso<
  TData = Awaited<ReturnType<typeof getIso>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getIso>>, TError, TData>
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetIsoQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetIsoSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getIso>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof getIso>>, TError, TData>
  >;
  request?: SecondParameter<typeof httpsInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetIsoQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getIso>>> = ({
    signal,
  }) => getIso(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getIso>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetIsoSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getIso>>
>;
export type GetIsoSuspenseQueryError = ErrorType<unknown>;

export function useGetIsoSuspense<
  TData = Awaited<ReturnType<typeof getIso>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof getIso>>, TError, TData>
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetIsoSuspense<
  TData = Awaited<ReturnType<typeof getIso>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof getIso>>, TError, TData>
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetIsoSuspense<
  TData = Awaited<ReturnType<typeof getIso>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof getIso>>, TError, TData>
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetIsoSuspense<
  TData = Awaited<ReturnType<typeof getIso>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof getIso>>, TError, TData>
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetIsoSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const healthCheck = (
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<SuccessBodyString>(
    { url: `/hello`, method: 'GET', signal },
    options,
  );
};

export const getHealthCheckQueryKey = () => {
  return [`/hello`] as const;
};

export const getHealthCheckQueryOptions = <
  TData = Awaited<ReturnType<typeof healthCheck>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof healthCheck>>, TError, TData>
  >;
  request?: SecondParameter<typeof httpsInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getHealthCheckQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof healthCheck>>> = ({
    signal,
  }) => healthCheck(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof healthCheck>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type HealthCheckQueryResult = NonNullable<
  Awaited<ReturnType<typeof healthCheck>>
>;
export type HealthCheckQueryError = ErrorType<unknown>;

export function useHealthCheck<
  TData = Awaited<ReturnType<typeof healthCheck>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof healthCheck>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof healthCheck>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof httpsInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useHealthCheck<
  TData = Awaited<ReturnType<typeof healthCheck>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof healthCheck>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof healthCheck>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useHealthCheck<
  TData = Awaited<ReturnType<typeof healthCheck>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof healthCheck>>, TError, TData>
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useHealthCheck<
  TData = Awaited<ReturnType<typeof healthCheck>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof healthCheck>>, TError, TData>
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getHealthCheckQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getHealthCheckSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof healthCheck>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof healthCheck>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getHealthCheckQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof healthCheck>>> = ({
    signal,
  }) => healthCheck(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof healthCheck>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type HealthCheckSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof healthCheck>>
>;
export type HealthCheckSuspenseQueryError = ErrorType<unknown>;

export function useHealthCheckSuspense<
  TData = Awaited<ReturnType<typeof healthCheck>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof healthCheck>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useHealthCheckSuspense<
  TData = Awaited<ReturnType<typeof healthCheck>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof healthCheck>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useHealthCheckSuspense<
  TData = Awaited<ReturnType<typeof healthCheck>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof healthCheck>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useHealthCheckSuspense<
  TData = Awaited<ReturnType<typeof healthCheck>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof healthCheck>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getHealthCheckSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getUserAuthInfo = (
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<SuccessBodyGetUserAuthInfoResponse>(
    { url: `/auth/user-info`, method: 'GET', signal },
    options,
  );
};

export const getGetUserAuthInfoQueryKey = () => {
  return [`/auth/user-info`] as const;
};

export const getGetUserAuthInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserAuthInfo>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getUserAuthInfo>>, TError, TData>
  >;
  request?: SecondParameter<typeof httpsInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserAuthInfoQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserAuthInfo>>> = ({
    signal,
  }) => getUserAuthInfo(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserAuthInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserAuthInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserAuthInfo>>
>;
export type GetUserAuthInfoQueryError = ErrorType<unknown>;

export function useGetUserAuthInfo<
  TData = Awaited<ReturnType<typeof getUserAuthInfo>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getUserAuthInfo>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getUserAuthInfo>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof httpsInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetUserAuthInfo<
  TData = Awaited<ReturnType<typeof getUserAuthInfo>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getUserAuthInfo>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getUserAuthInfo>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetUserAuthInfo<
  TData = Awaited<ReturnType<typeof getUserAuthInfo>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getUserAuthInfo>>, TError, TData>
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetUserAuthInfo<
  TData = Awaited<ReturnType<typeof getUserAuthInfo>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getUserAuthInfo>>, TError, TData>
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetUserAuthInfoQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetUserAuthInfoSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserAuthInfo>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getUserAuthInfo>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserAuthInfoQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserAuthInfo>>> = ({
    signal,
  }) => getUserAuthInfo(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getUserAuthInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserAuthInfoSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserAuthInfo>>
>;
export type GetUserAuthInfoSuspenseQueryError = ErrorType<unknown>;

export function useGetUserAuthInfoSuspense<
  TData = Awaited<ReturnType<typeof getUserAuthInfo>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getUserAuthInfo>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetUserAuthInfoSuspense<
  TData = Awaited<ReturnType<typeof getUserAuthInfo>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getUserAuthInfo>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetUserAuthInfoSuspense<
  TData = Awaited<ReturnType<typeof getUserAuthInfo>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getUserAuthInfo>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetUserAuthInfoSuspense<
  TData = Awaited<ReturnType<typeof getUserAuthInfo>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getUserAuthInfo>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetUserAuthInfoSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const googleAuthorizationCallback = (
  params: GoogleAuthorizationCallbackParams,
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<RedirectView>(
    { url: `/auth/google/callback`, method: 'GET', params, signal },
    options,
  );
};

export const getGoogleAuthorizationCallbackQueryKey = (
  params: GoogleAuthorizationCallbackParams,
) => {
  return [`/auth/google/callback`, ...(params ? [params] : [])] as const;
};

export const getGoogleAuthorizationCallbackQueryOptions = <
  TData = Awaited<ReturnType<typeof googleAuthorizationCallback>>,
  TError = ErrorType<unknown>,
>(
  params: GoogleAuthorizationCallbackParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof googleAuthorizationCallback>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGoogleAuthorizationCallbackQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof googleAuthorizationCallback>>
  > = ({ signal }) =>
    googleAuthorizationCallback(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof googleAuthorizationCallback>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GoogleAuthorizationCallbackQueryResult = NonNullable<
  Awaited<ReturnType<typeof googleAuthorizationCallback>>
>;
export type GoogleAuthorizationCallbackQueryError = ErrorType<unknown>;

export function useGoogleAuthorizationCallback<
  TData = Awaited<ReturnType<typeof googleAuthorizationCallback>>,
  TError = ErrorType<unknown>,
>(
  params: GoogleAuthorizationCallbackParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof googleAuthorizationCallback>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof googleAuthorizationCallback>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGoogleAuthorizationCallback<
  TData = Awaited<ReturnType<typeof googleAuthorizationCallback>>,
  TError = ErrorType<unknown>,
>(
  params: GoogleAuthorizationCallbackParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof googleAuthorizationCallback>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof googleAuthorizationCallback>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGoogleAuthorizationCallback<
  TData = Awaited<ReturnType<typeof googleAuthorizationCallback>>,
  TError = ErrorType<unknown>,
>(
  params: GoogleAuthorizationCallbackParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof googleAuthorizationCallback>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGoogleAuthorizationCallback<
  TData = Awaited<ReturnType<typeof googleAuthorizationCallback>>,
  TError = ErrorType<unknown>,
>(
  params: GoogleAuthorizationCallbackParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof googleAuthorizationCallback>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGoogleAuthorizationCallbackQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGoogleAuthorizationCallbackSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof googleAuthorizationCallback>>,
  TError = ErrorType<unknown>,
>(
  params: GoogleAuthorizationCallbackParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof googleAuthorizationCallback>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGoogleAuthorizationCallbackQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof googleAuthorizationCallback>>
  > = ({ signal }) =>
    googleAuthorizationCallback(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof googleAuthorizationCallback>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GoogleAuthorizationCallbackSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof googleAuthorizationCallback>>
>;
export type GoogleAuthorizationCallbackSuspenseQueryError = ErrorType<unknown>;

export function useGoogleAuthorizationCallbackSuspense<
  TData = Awaited<ReturnType<typeof googleAuthorizationCallback>>,
  TError = ErrorType<unknown>,
>(
  params: GoogleAuthorizationCallbackParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof googleAuthorizationCallback>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGoogleAuthorizationCallbackSuspense<
  TData = Awaited<ReturnType<typeof googleAuthorizationCallback>>,
  TError = ErrorType<unknown>,
>(
  params: GoogleAuthorizationCallbackParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof googleAuthorizationCallback>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGoogleAuthorizationCallbackSuspense<
  TData = Awaited<ReturnType<typeof googleAuthorizationCallback>>,
  TError = ErrorType<unknown>,
>(
  params: GoogleAuthorizationCallbackParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof googleAuthorizationCallback>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGoogleAuthorizationCallbackSuspense<
  TData = Awaited<ReturnType<typeof googleAuthorizationCallback>>,
  TError = ErrorType<unknown>,
>(
  params: GoogleAuthorizationCallbackParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof googleAuthorizationCallback>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGoogleAuthorizationCallbackSuspenseQueryOptions(
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}
