import { QuestionmarkSmallCircleFillIcon } from '@zep/icons';
import { useTranslation } from 'next-i18next';
export function GoogleClassroomDisclaimer() {
  const {
    t
  } = useTranslation();
  return <div className="flex items-center gap-1.5 rounded-xs bg-neutral px-4 py-2" data-sentry-component="GoogleClassroomDisclaimer" data-sentry-source-file="GoogleClassroomDisclaimer.tsx">
      <QuestionmarkSmallCircleFillIcon className="size-4 text-assistive" data-sentry-element="QuestionmarkSmallCircleFillIcon" data-sentry-source-file="GoogleClassroomDisclaimer.tsx" />
      <p className="text-caption-lg-medium text-alternative">
        {t('googleClassroom.signInDisclaimer.text', 'Google Classroom에서 사용하는 것과 동일한 계정으로 로그인해 주세요')}
      </p>
    </div>;
}