import { useGoogleLogin } from '@react-oauth/google';
import { useLocale } from '@zep/hooks';
import { GoogleOAuthStorage } from '@zep/shared/googleClassroom';
import { googleClassroomContextManager } from '@zep/shared/googleClassroom/GoogleClassroomContextManager';
import { useTranslation } from 'next-i18next';
import { OauthButton } from './OauthButton';
export const GoogleLogin = (props: {
  onSuccess: ({
    accessToken,
    authType,
    localeCode
  }: {
    accessToken: string;
    authType: string;
    localeCode: string;
  }) => void;
}) => {
  const {
    t
  } = useTranslation();
  const {
    locale
  } = useLocale();
  const login = useGoogleLogin({
    scope: googleClassroomContextManager.getGoogleOAuthScope(),
    onSuccess: tokenResponse => {
      GoogleOAuthStorage.setToken(tokenResponse.access_token);
      props.onSuccess({
        accessToken: tokenResponse.access_token,
        authType: 'google',
        localeCode: locale
      });
    },
    onError: error => {
      alert(error);
    }
  });
  return <OauthButton onClick={() => login()} logo={<img className={'size-[16px]'} src="/assets/Icon_google.png" alt="google" />} data-sentry-element="OauthButton" data-sentry-component="GoogleLogin" data-sentry-source-file="GoogleLogin.tsx">
      {t('login.google.button')}
    </OauthButton>;
};