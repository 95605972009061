import { forwardRef, InputHTMLAttributes, ReactNode } from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import { cn } from '../../utils';
const inputVariants = cva(['space-x-2', 'inline-flex', 'bg-white', 'disabled:bg-gray-400', 'items-center', 'align-middle', 'font-medium', 'border', 'border-gray-300', 'placeholder:text-gray-500', 'focus-within:border-strong'], {
  variants: {
    size: {
      sm: ['px-[10px]', 'h-[36px]', 'rounded-sm', 'placeholder-text-body-sm-medium text-body-md-medium'],
      md: ['px-3', 'h-10', 'rounded-sm', 'text-body-lg']
    },
    error: {
      default: '',
      true: 'border-red-300 focus-within:border-red-300 focus:border-red-300'
    },
    disabled: {
      default: '',
      true: 'bg-gray-100 text-gray-400'
    }
  },
  defaultVariants: {
    size: 'md'
  }
});
export const Input = forwardRef<HTMLInputElement, InputProps>(function Input({
  leftSlot,
  rightSlot,
  className,
  size,
  error,
  inputClassName,
  ...props
}, ref) {
  return <div className={cn(inputVariants({
    size,
    error: error,
    disabled: props.disabled
  }), className)}>
      {leftSlot && <span className="whitespace-nowrap text-body-md !leading-none text-inherit">
          {leftSlot}
        </span>}
      <input type="text" ref={ref} className={cn('w-full bg-transparent text-inherit outline-none', 'disabled:text-gray-400', inputClassName)} autoComplete={'off'} {...props} />
      {rightSlot && <span className="whitespace-nowrap text-body-sm !leading-none text-inherit">
          {rightSlot}
        </span>}
    </div>;
});
type InputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> & VariantProps<typeof inputVariants> & {
  leftSlot?: ReactNode;
  rightSlot?: ReactNode;
  inputClassName?: string;
};