import { useEffect, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from '@zep/ui_v3';
import { combinePathAndQuery, isInAppBrowser } from '@zep/utils';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
export function InAppLoginPageView() {
  const {
    t
  } = useTranslation();
  const queryClient = useQueryClient();
  const {
    query
  } = useRouter();
  const openBrowser = () => {
    const externalBrowserLink = getInAppExternalLink(window.location.origin);
    window.open(combinePathAndQuery(externalBrowserLink, query), '_blank');
  };
  const isShownExternalLinkButton = useMemo(() => {
    if (window.navigator.userAgent.match(/kakaotalk|line/i)) {
      return true;
    }
    return !window.navigator.userAgent.match(/iphone|ipad|ipod/i);
  }, []);
  useEffect(() => {
    queryClient.resetQueries();
  }, []);
  return <div className={'flex h-screen w-screen flex-col items-center justify-center gap-xl'} data-sentry-component="InAppLoginPageView" data-sentry-source-file="InAppLoginPageView.tsx">
      <img src="/assets/ZEPQuiz-logo.svg" className={'h-auto w-[240px]'} alt="logo" />
      <p className={'text-heading-sm-bold'}>
        {t('inAppLogin.description.first')}
      </p>
      <img src="/assets/browsers.png" className={'w-[220px]'} alt="browsers" />
      <p className={'text-heading-sm-bold'}>
        {t('inAppLogin.description.second')}
      </p>
      {isShownExternalLinkButton && <Button intent={'gradient'} onClick={openBrowser}>
          {t('inAppLogin.open.button')}
        </Button>}
    </div>;
}

/**
 * 카카오톡이나 라인 등의 인앱에서 외부 링크를 열 때 사용하는 함수
 * 해당되지 않는 경우에는 일반 링크를 리턴한다
 * @param link 넣고자 하는 링크
 */
const getInAppExternalLink = (link: string) => {
  if (!isInAppBrowser()) {
    return link;
  }

  // 아는게 생길때마다 하나씩 추가 바람
  const kakaotalk = /kakaotalk/i;
  const line = /line/i;
  const iOS = /iphone|ipad|ipod/i;
  if (navigator.userAgent.match(kakaotalk)) {
    return `kakaotalk://web/openExternal?url=${encodeURIComponent(link)}`;
  } else if (navigator.userAgent.match(line)) {
    return `${link}${link.indexOf('?') !== -1 ? '&' : '?'}openExternalBrowser=1`;
  }
  if (navigator.userAgent.match(iOS)) {
    return link;
  } else {
    // 안드로이드는 intent 스키마 전달
    return `intent://${link.replace(/https?:\/\//i, '')}#Intent;scheme=http;package=com.android.chrome;end`;
  }
};