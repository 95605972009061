import { AuthResWithPlanType } from '@zep/api';
import { useUser } from '@zep/lib/auth';
import { UserType } from '@zep/types';
import { combinePathAndQuery } from '@zep/utils';
import { useRouter } from 'next/router';

export const useOnLoginSuccess = () => {
  const router = useRouter();
  const callbackUrl = router.query.callbackUrl?.toString() || '/public';
  const { setUser } = useUser();

  return async (data: AuthResWithPlanType) => {
    setUser({
      token: data.token,
      type: data.memberType as UserType,
      admin: data.isAdmin,
      email: data.email,
      username: data.userName,
      expiredAt: data.expiredAt,
      registrationStatus: data.registrationStatus,
      planType: data.planType,
    });

    if (!data.registrationStatus) {
      router.replace(combinePathAndQuery('/sign-up/type', router.query));
      return;
    }

    await router.push(callbackUrl);
  };
};
