import { useLoginWithGoogle } from '@zep/api';
import { useOnLoginSuccess } from '@zep/module/auth/login/hooks';
import { Conditional, useInitGoogleClassroomContextForRedirectPage } from '@zep/shared/googleClassroom';
import { combinePathAndQuery } from '@zep/utils';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { GoogleClassroomDisclaimer } from '../../../shared/googleClassroom/components/GoogleClassroomDisclaimer';
import { AuthContainer } from './components';
import { EmailInputForm } from './EmailVerify';
import { GoogleLogin } from './OAuth';
export function LoginPageView() {
  const onLoginSuccess = useOnLoginSuccess();
  const {
    t
  } = useTranslation();
  const router = useRouter();
  const {
    mutate
  } = useLoginWithGoogle({
    mutation: {
      onSuccess: async data => {
        if (data.data) {
          await onLoginSuccess(data.data);
        }
      },
      onError: error => {
        console.error(error);
        alert(t('common.error.unknown', {
          message: JSON.stringify(error)
        }));
      }
    }
  });
  const goVerifyPage = async (email: string) => {
    await router.push(combinePathAndQuery('/auth/verify', {
      ...router.query,
      email
    }));
  };
  useInitGoogleClassroomContextForRedirectPage();
  return <>
      <AuthContainer data-sentry-element="AuthContainer" data-sentry-source-file="LoginPageView.tsx">
        <Header data-sentry-element="Header" data-sentry-source-file="LoginPageView.tsx" />
        <div className={'flex w-full flex-col gap-xs'}>
          <GoogleLogin onSuccess={({
          accessToken,
          authType
        }) => mutate({
          data: {
            accessToken,
            authType
          }
        })} data-sentry-element="GoogleLogin" data-sentry-source-file="LoginPageView.tsx" />
        </div>
        <Conditional inGoogleClassroom={<GoogleClassroomDisclaimer />} data-sentry-element="Conditional" data-sentry-source-file="LoginPageView.tsx">
          <Or data-sentry-element="Or" data-sentry-source-file="LoginPageView.tsx" />
          <EmailInputForm onSuccess={goVerifyPage} data-sentry-element="EmailInputForm" data-sentry-source-file="LoginPageView.tsx" />
        </Conditional>
      </AuthContainer>
      <BgBottomImage data-sentry-element="BgBottomImage" data-sentry-source-file="LoginPageView.tsx" />
    </>;
}
const Or = () => <div className={'flex w-full  flex-row items-center gap-xs'} data-sentry-component="Or" data-sentry-source-file="LoginPageView.tsx">
    <div className={'h-[1px] w-full bg-alternative'}></div>
    <div className={'text-body-sm-medium text-assistive'}>or</div>
    <div className={'h-[1px] w-full bg-alternative'}></div>
  </div>;
const Header = () => {
  const {
    t
  } = useTranslation();
  return <div className={'mt-md flex h-fit w-full flex-col items-center justify-center gap-xs'} data-sentry-component="Header" data-sentry-source-file="LoginPageView.tsx">
      <img className={'h-[40px] w-[182.5px]'} src="/assets/ZEPQuiz-logo.svg" alt="logo" />
      <span className={'pb-md text-center text-body-md-medium text-neutral'}>
        {t('auth.login.easyFunQuiz', '쉽게 만드는 재미있는 방탈출 퀴즈')}
      </span>
    </div>;
};
const BgBottomImage = () => <img className={'absolute bottom-0 left-2/4 z-0 w-[480px]  -translate-x-2/4'} src={'/assets/login_background.webp'} alt={'login-bg'} data-sentry-component="BgBottomImage" data-sentry-source-file="LoginPageView.tsx" />;