import { useEffect } from 'react';
import { useSearchParams } from 'next/navigation';

import { googleClassroomContextManager } from '../GoogleClassroomContextManager';

export function useInitGoogleClassroomContextForRedirectPage() {
  const searchParams = useSearchParams();

  // 구글 클래스룸에서 진입 후 리다이렉트된 경우, landingPageContext를 초기화합니다.
  useEffect(() => {
    const callbackUrl = searchParams.get('callbackUrl');
    if (callbackUrl) {
      // e.g. '/google-classroom/teacher-landing?gc_landingPageType=AttachmentSetup&addOnToken=AJaYm_3wP783lBQyAG0zoG1riVtr%3A1734079582055&courseId=731346739420&postId=732858298583&itemId=732858298583&itemType=courseWork&login_hint=114389486751463934642'
      if (decodeURIComponent(callbackUrl).startsWith('/google-classroom')) {
        const searchForCallbackUrl = `?${callbackUrl.split('?')[1]}`;
        googleClassroomContextManager.initializeLandingPageContext(
          new URLSearchParams(searchForCallbackUrl),
        );
      }
    }
  }, [searchParams]);
}
